import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faXTwitter } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="container-fluid bg-dark text-body footer py-4 mt-4 wow fadeIn" data-wow-delay="0.1s">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">{t('address')}</h5>
            <p className="mb-2"><i className="fa fa-map-marker-alt me-3" /> {t('addressDetails')}</p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3" />+908504410497</p>
            <p className="mb-2"><i className="fa fa-envelope me-3" />info@hizirglobal.com</p>
            <div className="d-flex justify-content-center ms-md-auto pt-2">
              <a className="btn btn-square btn-outline-light btn-social me-2" href="https://x.com/i/flow/login?redirect_after_login=%2Fyetispluscarsi" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faXTwitter} />
              </a>
              <a className="btn btn-square btn-outline-light btn-social me-2" href="https://www.facebook.com/people/Yeti%C5%9F-%C3%87ar%C5%9F%C4%B1/61559228634077/"><i className="fab fa-facebook-f" /></a>
              <a className="btn btn-square btn-outline-light btn-social me-2" href="https://www.youtube.com/channel/UCC-keDOCc2XnclCDJ9_xFtQ"><i className="fab fa-youtube" /></a>
              <a className="btn btn-square btn-outline-light btn-social" href="https://www.instagram.com/yetispluscarsi/"><i className="fab fa-instagram" /></a>
              <a className="btn btn-square btn-outline-light btn-social" href="https://www.linkedin.com/company/yetispluscarsi/"><i className="fab fa-linkedin-in" /></a>
              <a className="btn btn-square btn-outline-light btn-social me-2" href="https://www.tiktok.com/@yetispluscarsi"> <FontAwesomeIcon icon={faTiktok} /></a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">{t('pages')}</h5>
            <div className="d-flex flex-column align-items-center">
              <a className="btn btn-link" href="/">{t('home')}</a>
              <a className="btn btn-link" href="/hakkimizda">{t('about')}</a>
              <a className="btn btn-link" href="/projeler">{t('projects')}</a>
              <a className="btn btn-link" href="/iletisim">{t('contact')}</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-4">
        <div className="copyright">
          <div className="row">
            <div className="text-center text-md-end mb-3 mb-md-0">
              <p>© <a href="#">HIZIR GLOBAL AŞ</a>, {t('allRightsReserved')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
