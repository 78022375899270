import React from 'react';
import { Link } from 'react-router-dom';
import services from '../data/service.js'; // Proje verilerini içeren dosyayı import et
import { useTranslation } from 'react-i18next';

const Services = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        {/* Page Header Start */}
        <div className="container-fluid page-header py-5 mb-5">
          <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">{t('services')}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a className="text-white" href="/">{t('home')}</a></li>
                <li className="breadcrumb-item text-white active" aria-current="page">{t('services')}</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* Page Header End */}
        {/* Service Start */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: 600 }}>
              <h1 className="mb-4">{t('our-services')}</h1>
            </div>
            
            <div className="row g-4 portfolio-container wow fadeInUp" data-wow-delay="0.5s">
              {services.map((service) => (
                <div key={service.id} className={`col-lg-4 col-md-6 portfolio-item`}>
                  <div className="portfolio-img rounded overflow-hidden">
                    <img className="img-fluid" src={service.image} alt="" style={{ height: '220px', width: '100%' }} />
                    <div className="portfolio-btn">
                      <Link to={`/hizmetler/hizmet-detay/${service.id}`} className="btn btn-lg btn-outline-light rounded-circle mx-1"><i className="fa fa-eye" /></Link>
                    </div>
                  </div>
                  <div className="pt-3">
                    <hr className="text-primary w-25 my-2" />
                    <h5 className="lh-base">{service.title}</h5>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Service End */}
      </div>
    </div>
  );
}

export default Services;
