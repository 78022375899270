import React from 'react';
import { useParams } from 'react-router-dom';
import services from '../data/service.js';

const ServiceDetail = () => {
  const { id } = useParams();
  const service = services.find((p) => p.id === parseInt(id));

  if (!service) {
    return <div>Service not found</div>;
  }

  return (
    <div>
      {/* About Start */}
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 align-items-center">
            <div className="col-lg-6 ps-lg-4 ps-md-3 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: 400 }}>
              <div className="position-relative h-100">
              <img className="img-fluid w-100 h-70" src={process.env.PUBLIC_URL + '/' + service.image} style={{ objectFit: 'cover' }} alt={service.title} />

              </div>
            </div>
            <div className="col-lg-6 about-text py-5 wow fadeIn text-dark" data-wow-delay="0.5s">
              <div className="p-lg-5 pe-lg-0 text-left">
                <div className="container py-5">
                  <h1>{service.title}</h1>
                </div>
                <div className="container py-3">
                  <p className="mb-0" style={{ fontWeight: 'bold', lineHeight: '1.6', fontSize: '1.1rem' }}>
                    {service.description.map((item, index) => (
                      <span key={index}>{item}<br /></span>
                    ))}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
    </div>
  );
}

export default ServiceDetail;
