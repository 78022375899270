import React from 'react';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();
  return (
    <div>
      {/* Page Header Start */}
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
          <h1 className="display-3 text-white mb-3 animated slideInDown">{t('about')}</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a className="text-white" href="/">{t('home')}</a></li>
              <li className="breadcrumb-item text-white active" aria-current="page">{t('about')}</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
 
    {/* About Start */}
    <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
      <div className="container about px-lg-0">
        <div className="row g-0 align-items-center">
          <div className="col-lg-6 ps-lg-4 ps-md-3 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: 400 }}>
            <div className="position-relative h-100">
              <img className="img-fluid w-100 h-70" src="img/hizirglobal_logo.jpg" style={{ objectFit: 'cover' }} alt="About Us" />
            </div>
          </div>
          <div className="col-lg-6 about-text py-5 wow fadeIn text-dark" data-wow-delay="0.5s">
            <div className="p-lg-5 pe-lg-0 text-left">
              <p>
                <h6><strong>{t('whoWeAre')}</strong></h6>
                {t('whoWeAreText')}
              </p>
              <p>
                <h6><strong>{t('ourPurpose')}</strong></h6>
                {t('ourPurposeText')}
              </p>
              <p>
                <h6><strong>{t('ourMission')}</strong></h6>
                {t('ourMissionText')}
              </p>
              <p>
                <h6><strong>{t('ourVision')}</strong></h6>
                {t('ourVisionText')}
              </p>
              <p>
                <h6><strong>{t('ourValues')}</strong></h6>
                {t('ourValuesText')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* About End */}
    </div>
  );
};

export default About;
