import React from 'react';
const services = [
  { 
    id: 1, 
    title: 'Yetiş Depo',  
    image: 'img/depo.jpg',
    description: [
      <React.Fragment>
          <p>Yetiş Depo, online mağazalara ve e-ticaret firmalarına depolama ve teslimat hizmeti sunan bir platformdur. Modern ve güvenli tesislerimizde ürünlerinizi depolayabilir, siparişlerinizi hızlı ve güvenli bir şekilde son müşterilerinize teslim edebilirsiniz.'</p>
          <h4>Yetiş Depo ile Neler Yapabilirsiniz?</h4>
          <ul>
            <li>Ürünlerinizi güvenle depolayabilirsiniz.</li>
            <li>Siparişlerinizi hızlı ve güvenli bir şekilde teslim edebilirsiniz.</li>
            <li>Stok takibi ve sipariş yönetimi gibi lojistik operasyonlarınızı kolaylaştırabilirsiniz.</li>
            <li>Zamandan ve paradan tasarruf edebilirsiniz.</li>
            <li>Uzman ekibimizden destek alabilirsiniz.</li>
          </ul>
          <h4>Yetiş Depo'nun Avantajları Nelerdir?</h4>
          <ul>
            <li>Modern ve Güvenli Tesisler: Ürünleriniz en son teknoloji ile donatılmış tesislerimizde depolanır.</li>
            <li>7/24 Güvenlik: Depolama alanlarımız 7/24 kamera sistemi ve güvenlik görevlileri tarafından korunmaktadır.</li>
            <li>Hızlı Teslimat: Siparişlerinizi son müşteriye hızlı ve güvenli bir şekilde teslim ediyoruz.</li>
            <li>Stok Takibi ve Sipariş Yönetimi: Stokunuzu anlık olarak takip edebilir ve siparişlerinizi kolayca yönetebilirsiniz.</li>
            <li>Uygun Fiyatlar: Bütçenize uygun depolama ve teslimat çözümleri sunmaktayız.</li>
            <li>Uzman Ekip: Depolama, lojistik ve teslimat konusunda deneyimli ekibimiz her zaman size yardımcı olmaya hazırdır.</li>
          </ul>
          <p>'Yetiş Depo ile E-Ticaret İşinizi Büyütün! Yetiş Depo, online mağazanızın lojistik yükünü hafifletmek ve satışlarınızı artırmak için ideal bir çözümdür. Hemen Yetiş Depo ile iletişime geçin ve ücretsiz danışmanlık hizmetimizden yararlanın.'</p>


      </React.Fragment>
    ]
  },
  {
    id: 2,
    title: 'Hızır Kapıda',
    image: 'img/hizir_kapida.jpg',
    description: [
      
      <React.Fragment>
        <p><strong>Hızır Kapıda</strong>, tıpkı Hızır Kurye gibi, kapınızda hızlı ve güvenli teslimat hizmeti sunan bir platformdur. Market alışverişlerinizden, lezzetli yemeklere, ilaç siparişlerinize kadar her türlü ihtiyacınızı Hızır Kapıda ile karşılayabilirsiniz.</p>

        <h4>Hızır Kapıda ile Neler Yapabilirsiniz?</h4>
        <ul>
          <li>Market alışverişlerinizi online olarak yapabilir ve kapınıza kadar teslim alabilirsiniz.</li>
          <li>Sevdiğiniz restoranlardan lezzetli yemekler sipariş edebilir ve evinizin konforunda keyfini çıkarabilirsiniz.</li>
          <li>Eczanelerden ilaçlarınızı sipariş edip, evden çıkmadan teslim alabilirsiniz.</li>
          <li>Diğer ihtiyaç ürünlerinizi de Hızır Kapıda ile kolayca sipariş edebilirsiniz.</li>
        </ul>

        <h4>Hızır Kapıda'nın Avantajları Nelerdir?</h4>
        <ul>
          <li>Zamandan tasarruf edersiniz.</li>
          <li>Evden çıkmadan alışverişlerinizi yapabilirsiniz.</li>
          <li>Lezzetli yemekleri sevdiğiniz restoranlardan sipariş edebilirsiniz.</li>
          <li>İlaçlarınızı eczaneden sipariş edip, evden çıkmadan teslim alabilirsiniz.</li>
          <li>Dokümanlarınızı hızlı ve güvenli bir şekilde gönderebilirsiniz.</li>
          <li>Ve çok daha fazlası!</li>
        </ul>

        <h4>Hızır Kapıda Nasıl Kullanılır?</h4>
        <ol>
          <li>Hızır Kapıda mobil uygulamasını indirin.</li>
          <li>Hesap oluşturun veya giriş yapın.</li>
          <li>Konumunuzu seçin.</li>
          <li>Sipariş vermek istediğiniz kategorileri seçin.</li>
          <li>Siparişlerinizi ekleyin ve sepetinizi kontrol edin.</li>
          <li>Ödeme yönteminizi seçin.</li>
          <li>Siparişinizi onaylayın.</li>
          <li>Siparişinizin durumunu takip edin.</li>
          <li>Siparişinizi teslim alın ve keyfini çıkarın!</li>
        </ol>

        <p><strong>Hızır Kapıda ile Hayatınız Kolaylaşsın!</strong> Hızır Kapıda ile zamandan tasarruf edebilir, evden çıkmadan alışverişlerinizi yapabilir, lezzetli yemekler yiyebilirsiniz.</p>
      </React.Fragment>,
    
     
    ]
  },
  
  { id: 3, title: 'Hızır Kurye', image: 'img/hizir_kurye.png' ,
    description: [
      <React.Fragment>
        <p>Hızır Kurye, Türkiye'nin önde gelen kurye ve lojistik firmalarından biri olarak Ankara'dan tüm Türkiye'ye hizmet vermektedir. Müşterilerimize hızlı, güvenilir ve profesyonel teslimat hizmeti sunmaya odaklanmış durumdayız.</p>
        <h4>Misyonumuz:</h4>
        <p>Misyonumuz, müşterilerimizin ihtiyaç ve beklentilerini en üst düzeyde karşılamak, lojistik sektöründe öncü bir firma olmak ve sektöre yenilikler kazandırmaktır.</p>
        <h4>Hızlı ve Güvenilir Teslimatlar:</h4>
        <p>Hızır Kurye, geniş araç filosu ve deneyimli ekibi ile tüm gönderilerinizi hızlı ve güvenli bir şekilde teslim etmeyi taahhüt eder. Aynı gün teslimat, ertesi gün teslimat ve kargo teslimatı gibi farklı teslimat seçenekleri sunarak her ihtiyaca cevap veriyoruz.</p>
        <h4>Geniş Hizmet Yelpazesi:</h4>
        <p>Hızır Kurye, bireysel müşterilere ve kurumsal firmalara sunduğu geniş hizmet yelpazesi ile her türlü lojistik ihtiyacınızı karşılayabilir.</p>
        <ul>
          <li>Evden Eve Taşımacılık</li>
          <li>Ofis Taşımacılığı</li>
          <li>Kargo Gönderimi</li>
          <li>E-Ticaret Lojistiği</li>
        </ul>
         <h4>Deneyimli Ekip:</h4>
        Hızır Kurye, alanında uzman ve deneyimli bir ekiple çalışmaktadır. Ekibimiz, her türlü lojistik operasyonu titizlikle planlamakta ve uygulamaktadır.
      </React.Fragment>,
    
    ]
  }
];

export default services;
