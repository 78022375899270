import React from 'react';
import { useParams } from 'react-router-dom';
import projects from '../data/project.js';

const ProjectDetail = () => {
  const { id } = useParams();
  const project = projects.find((p) => p.id === parseInt(id));

  if (!project) {
    return <div>Proje bulunamadı.</div>;
  }

  return (
    <div>
      {/* Project Detail Start */}
      <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 align-items-center flex-column">
            <div className="col-12 wow fadeIn" data-wow-delay="0.1s" style={{ minHeight: 400, display: 'flex', justifyContent: 'center' }}>
              <div className="position-relative" style={{ width: '70%', height: 'auto' }}>
                <img
                  className="img-fluid w-100"
                  src={process.env.PUBLIC_URL + '/' + project.image}
                  style={{ objectFit: 'cover' }}
                  alt={project.title}
                />
              </div>
            </div>
            <div className="col-12 about-text py-5 wow fadeIn text-dark" data-wow-delay="0.5s">
              <div className="p-lg-5 pe-lg-0 text-left">
                <div className="container py-5">
                  <h1>{project.title}</h1>
                </div>
                <div className="container py-3">
                  {Array.isArray(project.description) ? (
                    <React.Fragment>
                      {project.description.map((item, index) => (
                        <div key={index}>
                          {typeof item === 'string' ? (
                            <p>{item}</p>
                          ) : (
                            item /* Eğer bir JSX ise doğrudan kullanılır */
                          )}
                        </div>
                      ))}
                    </React.Fragment>
                  ) : (
                    <p>{project.description}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Project Detail End */}
    </div>
  );
};

export default ProjectDetail;
