import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Footer from './components/footer';
import Header from './components/header';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Projects from './pages/Projects';
import ServiceDetail from './pages/ServiceDetail';
import ProjectDetail from './pages/ProjectDetail';
import './App.css';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';




function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/iletisim" element={<Contact />} />
          <Route path="/hizmetler" element={<Services />} />
          <Route path="/hizmetler/hizmet-detay/:id" element={<ServiceDetail />} />
          <Route path="/projeler" element={< Projects />} />
          <Route path="/projeler/proje-detay/:id" element={<ProjectDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}
export default App;