import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div>
      {/* Navbar Start */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <a href="/">
          <img src="/img/hizirglobal_logo.jpg" alt="Hızır Global" style={{ maxWidth: '100px', marginLeft: 20 }} />
        </a>
        <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <NavLink exact to="/" className="nav-item nav-link" activeClassName="active">{t('home')}</NavLink>
            <NavLink to="/hakkimizda" className="nav-item nav-link" activeClassName="active">{t('about')}</NavLink>
            <NavLink to="/projeler" className="nav-item nav-link" activeClassName="active">{t('projects')}</NavLink>
            <NavLink to="/hizmetler" className="nav-item nav-link" activeClassName="active">{t('services')}</NavLink>
            
            {/* Language Selector */}
            <div className="nav-item dropdown" >
              <a className="nav-link dropdown-toggle" href="/" id="languageDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {t('language')}
              </a>
              <ul className="dropdown-menu" aria-labelledby="languageDropdown">
                <li><button className="dropdown-item" onClick={() => changeLanguage('en')}>{t('english')}</button></li>
                <li><button className="dropdown-item" onClick={() => changeLanguage('tr')}>{t('turkish')}</button></li>
              </ul>
            </div>
            {/* End Language Selector */}

            <NavLink to="/iletisim" className="nav-item nav-link" activeClassName="active">{t('contact')}</NavLink>
            
          </div>
        </div>
      </nav>
      {/* Navbar End */}
    </div>
  );
}

export default Header;
