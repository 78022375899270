import React from 'react';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div>
        {/* Page Header Start */}
        <div className="container-fluid page-header py-5 mb-5">
          <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">{t('contact')}</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a className="text-white" href="/">{t('home')}</a></li>
                <li className="breadcrumb-item text-white active" aria-current="page">{t('contact')}</li>
              </ol>
            </nav>
          </div>
        </div>
        {/* Page Header End */}

        {/* Contact Start */}
        <div className="container-fluid bg-light overflow-hidden px-lg-0" style={{ margin: '6rem 0' }}>
          <div className="container contact px-lg-0">
            <div className="row g-0 mx-lg-0" style={{ marginLeft: '15px', marginRight: '15px' }}>
              <div className="col-lg-6 contact-text py-5 wow fadeIn" data-wow-delay="0.5s" style={{ paddingRight: '15px' }}>
                <div className="p-lg-5 ps-lg-0">
                  <h1 className="mb-4">{t('getInTouch')}</h1>

                  <form>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="name" placeholder={t('yourName')} />
                          <label htmlFor="name">{t('name')}</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input type="email" className="form-control" id="email" placeholder={t('yourEmail')} />
                          <label htmlFor="email">{t('email')}</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="subject" placeholder={t('subject')} />
                          <label htmlFor="subject">{t('subject')}</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <textarea className="form-control" placeholder={t('leaveMessage')} id="message" style={{ height: 100 }} defaultValue={""} />
                          <label htmlFor="message">{t('message')}</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">{t('sendMessage')}</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 pe-lg-0" style={{ minHeight: 400, paddingLeft: '15px', position: 'relative', marginBlockEnd: 80 }}>
                <div className="position-relative h-100">
                  <iframe
                    style={{ width: '100%', height: '100%', border: 0, position: 'absolute', top: 0, left: 0 }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12063.702589352644!2d28.82135!3d41.060618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14caba9d95b75a27%3A0x2f0f4e6a3b63a18b!2sMahmutbey%2C%204%2F2.%20Sk.%20No%3A1%2C%2034218%20Ba%C4%9Fc%C4%B1lar%2F%C4%B0stanbul!5e0!3m2!1str!2str!4v1699392345619!5m2!1str!2str"
                    frameBorder={0}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact End */}
      </div>
    </div>
  );
};

export default Contact;
